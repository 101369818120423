/***********************************/
// Capa de Servicios para el modulo.

import axios from "axios";
import store from "@/store/store.js";

export default new class {
    
    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + sessionStorage.getItem('token'),
        }
    };
    
    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }
    
    async getAllRecords() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/investors", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getViewList() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/view-list/investors", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getRecord(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/investors/${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async getInvestor(id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/show-investor/investors?uid=${id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getProjectInvestor(project_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/show-project-investor/investors?project_id=${project_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getListProjectsInvestor(investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/list-projects-investor/investors?investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getListProjectsEvents(investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/list-projects-events/investors?investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getListInvestorEvents(investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/list-investor-events/investors?investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewProjectsEvents(project_id, investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/view-projects-events/investors?project_id=${project_id}&investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    // Reporte de datos para el dashboard
    async getReportProjectsInvestor(investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/report-projects-investor/investors?investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getReportDetailProjectsInvestor(project_id, investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/report-detail-projects-investor/investors?project_id=${project_id}&investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getReportDetailEventsInvestor(investor_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/report-detail-events-investor/investors?investor_id=${investor_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getReportDetailEventsByIdInvestor(investor_id, event_id) {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(`${this.#baseUrl}/report-detail-event-id-investor/investors?investor_id=${investor_id}&event_id=${event_id}`, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async listInvestors() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/section-list/investors", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getAllInvestors() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/investors/notifications/all", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async listUsers() {
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.get(this.#baseUrl + "/list-users/investors", this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async addRecord(formData) {
        let result = null;
        // let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("user_id", formData.user.id);
        recordData.append("investment_type", 'Inversion a plazo fijo.' /*formData.investment_type*/);
        try {
            this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
            let response = await axios.post(this.#baseUrl + "/investors", recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                // let investor_id = data.id;
                // Sincroniza las plataformas
                /*let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(investor_id, projectIds);*/
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }
    
    // Pushes posts to the server when called.
    async updateRecord(formData) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        let result = null;
        // let projectsList = formData.projects;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("user_id", formData.user.id);
        recordData.append("investment_type", 'Inversion a plazo fijo.' /*formData.investment_type*/);
        recordData.append('_method', 'put');
        try {
            let response = await axios.post(`${this.#baseUrl}/investors/${formData.id}`, recordData, this.header);
            if (response.data.success) {
                let data = response.data;
                // let investor_id = data.id;
    
                // Sincroniza las plataformas
                /*let projectIds = [];
                projectsList.forEach(item => {
                    projectIds.push(item.id);
                });
                this.registerProjects(investor_id, projectIds);*/
                
                result = data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }
    
    async deleteRecord(id) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let response = await axios.delete(`${this.#baseUrl}/investors/${id}`, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async registerProject(data) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let formData = new FormData();
            formData.append("investor_id", data.investor_id);
            formData.append("project_id", data.project_id);
            formData.append("amount", '0');
            formData.append("points", '0');
            formData.append("incomes", '0');
            formData.append("expenses", '0');
            formData.append("utility", '0');
            let response = await axios.post(this.#baseUrl + '/register-project/investors', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async updateProject(data) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let formData = new FormData();
            formData.append("investor_id", data.investor_id);
            formData.append("project_id", data.project_id);
            formData.append("amount", data.amount);
            formData.append("points", data.points);
            formData.append("incomes", data.incomes);
            formData.append("expenses", data.expenses);
            formData.append("utility", data.utility);
            let response = await axios.post(this.#baseUrl + '/update-project/investors', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    async deleteProject(investor_id, project_id) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let formData = new FormData();
            formData.append("investor_id", investor_id);
            formData.append("project_id", project_id);
            let response = await axios.post(this.#baseUrl + '/delete-project/investors', formData, this.header);
            if (response.data.success) {
                // console.log('Respuesta: ', response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async registerEvent(data) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let formData = new FormData();
            formData.append("investor_id", data.investor_id);
            formData.append("event_id", data.selected_event_id);
            let response = await axios.post(this.#baseUrl + '/register-event/investors', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async updateEvent(data) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            console.log('data: ', data);
            let formData = new FormData();
            formData.append("investor_id", data.investor_id);
            formData.append("event_id", data.event_id);
            formData.append("amount", data.amount);
            formData.append("points", data.points);
            formData.append("income", data.income);
            formData.append("expense", data.expense);
            formData.append("utility", data.utility);
            formData.append("perc_utility", data.perc_utility);
            let response = await axios.post(this.#baseUrl + '/update-event/investors', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async deleteEvent(investor_id, event_id) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            if (event_id) {
                let formData = new FormData();
                formData.append("investor_id", investor_id);
                formData.append("event_id", event_id);
                let response = await axios.post(this.#baseUrl + '/delete-event/investors', formData, this.header);
                if (response.data.success) {
                    return response.data;
                } else {
                    return null;
                }
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async registerProjectEvent(project_id, event_id, project_event_id, amount, utility) {
        this.header.headers.Authorization = 'Bearer ' + sessionStorage.getItem('token');
        try {
            let formData = new FormData();
            formData.append("project_id", project_id);
            formData.append("event_id", event_id);
            formData.append("project_event_id", project_event_id);
            formData.append("amount", amount);
            formData.append("utility", utility);
            let response = await axios.post(this.#baseUrl + '/register-project-event/investors', formData, this.header);
            if (response.data.success) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }
    
    handleError(error) {
        let valueError;
        if (error.response) {
            valueError = error.response.data.messaje;
        } else if (error.request) {
            valueError = error.request.data;
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }
}
